var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-management"},[_c('div',{staticClass:"mainbody"},[_c('div',{staticClass:"info"},[_vm._m(0),_c('a-row',{staticClass:"ptl10"},[_c('a-col',{staticClass:"lh32",attrs:{"span":2}},[_vm._v("订单日期：")]),_c('a-col',{attrs:{"span":10}},[_c('a-range-picker',{on:{"change":_vm.onChange}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"calendar"},slot:"suffixIcon"})],1)],1)],1),_c('a-row',{staticClass:"ptl10"},[_c('a-col',{staticClass:"lh32",attrs:{"span":1}},[_vm._v("搜索：")]),_c('a-col',{attrs:{"span":9,"align":"center"}},[_c('a-input-search',{staticStyle:{"width":"350px"},attrs:{"placeholder":"请输入卖家名称"},on:{"search":_vm.onSearch}})],1)],1),_c('div',[_c('div',{staticStyle:{"margin-bottom":"26px"}}),_c('a-table',{attrs:{"rowKey":"id","columns":_vm.columns,"size":"middle","data-source":_vm.orderList.list,"pagination":{
            size: 'middle',
            total: _vm.getsellerlist.total,
            current: _vm.getsellerlist.pageNum,
            pageSize: _vm.getsellerlist.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: function (total) { return ("共有 " + (_vm.getsellerlist.total) + " 条数据"); },
            showQuickJumper: true,
            onShowSizeChange: function (current, pageSize) {
              _vm.getsellerlist.pageSize = pageSize
              _vm.getsellerlist.pageNum = 1
              this$1.getlist()
            },
            onChange: function (pageNum) {
              _vm.getsellerlist.pageNum = pageNum
              this$1.getlist()
            }
          }},scopedSlots:_vm._u([{key:"id",fn:function(text){return [_c('router-link',{staticClass:"id-primary",attrs:{"to":("/order?id=" + text + "&entityId=" + (_vm.$route.query.entityId))}},[_vm._v(_vm._s(text))])]}},{key:"taxableAmount",fn:function(text){return [_c('span',[_vm._v(_vm._s(text.toFixed(2))+"元")])]}},{key:"vatTaxAmount",fn:function(text){return [_c('span',[_vm._v(_vm._s(text.toFixed(2))+"元")])]}},{key:"quantity",fn:function(text, record){return [_c('span',[_vm._v(_vm._s(text))]),_c('span',[_vm._v(_vm._s(_vm._f("unit")(record.unitsMeasure)))])]}},{key:"invoiceStatus",fn:function(text){return [_c('a-badge',{attrs:{"status":_vm._f("color")(text),"text":_vm._f("type")(text)}})]}},{key:"schedule",fn:function(text, record){return [_c('p',[_vm._v("合同："+_vm._s(record.contractPro))]),_c('p',[_vm._v("金额："+_vm._s(record.paymentPro))]),_c('p',[_vm._v("物流："+_vm._s(record.logisticsPro))])]}},{key:"operation",fn:function(text, record){return [(record.invoiceStatus === 'WAIT_APPLY')?_c('p',[_c('a-popconfirm',{attrs:{"cancelText":"取消","okText":"确定","title":"确定申请?"},on:{"confirm":function () { return _vm.putInvo(record.id); }}},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("申请开票")])])],1):_vm._e()]}}])}),_c('a-modal',{attrs:{"title":"支付","visible":_vm.visible,"footer":null,"width":990},on:{"cancel":_vm.cancel}},[(_vm.visible)?_c('PayMent',{attrs:{"id":_vm.$route.query.entityId,"authRoleId":_vm.authRoleId,"orderId":_vm.orders.orderId}}):_vm._e()],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-header"},[_c('b',[_vm._v("订单管理")])])}]

export { render, staticRenderFns }