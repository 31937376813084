<template>
  <div class="order-management">
    <div class="mainbody">
      <div class="info">
        <div class="info-header">
          <b>订单管理</b>
        </div>
        <!-- <a-row type="flex" align="middle">
          <a-col :span="2"> 订单状态：</a-col>
          <a-col :span="2">全部</a-col>
          <a-col :span="2"> 待付款 </a-col>
          <a-col :span="2"> 部分付款 </a-col>
          <a-col :span="2"> 已完成</a-col>
          <a-col :span="2"> 已取消 </a-col>
        </a-row> -->
        <a-row class="ptl10">
          <a-col :span="2" class="lh32">订单日期：</a-col>
          <a-col :span="10">
            <a-range-picker @change="onChange">
              <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
          </a-col>
        </a-row>

        <a-row class="ptl10">
          <a-col :span="1" class="lh32">搜索：</a-col>
          <a-col :span="9" align="center"
            ><a-input-search
              placeholder="请输入卖家名称"
              style="width: 350px"
              @search="onSearch"
          /></a-col>
        </a-row>
        <!-- <a-row class="contcat">
          <a-col :span="4">
            <a-button type="primary" class="cl69 btn">
              <a-icon type="credit-card" />合并付款
            </a-button>
          </a-col>
          <a-col :span="4">
            <a-button type="primary" class="c130 btn"> 一键下载 </a-button>
          </a-col>
        </a-row> -->
        <!-- 订单 -->
        <div>
          <div style="margin-bottom: 26px"></div>
          <a-table
            rowKey="id"
            :columns="columns"
            size="middle"
            :data-source="orderList.list"
            :pagination="{
              size: 'middle',
              total: getsellerlist.total,
              current: getsellerlist.pageNum,
              pageSize: getsellerlist.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: (total) => `共有 ${getsellerlist.total} 条数据`,
              showQuickJumper: true,
              onShowSizeChange: (current, pageSize) => {
                getsellerlist.pageSize = pageSize
                getsellerlist.pageNum = 1
                this.getlist()
              },
              onChange: (pageNum) => {
                getsellerlist.pageNum = pageNum
                this.getlist()
              }
            }"
          >
            <template slot="id" slot-scope="text">
              <router-link :to="`/order?id=${text}&entityId=${$route.query.entityId}`" class="id-primary">{{
                text
              }}</router-link>
            </template>
            <template slot="taxableAmount" slot-scope="text">
              <span>{{ text.toFixed(2) }}元</span>
            </template>
            <template slot="vatTaxAmount" slot-scope="text">
              <span>{{ text.toFixed(2) }}元</span>
            </template>
            <template slot="quantity" slot-scope="text, record">
              <span>{{ text }}</span
              ><span>{{ record.unitsMeasure | unit }}</span>
            </template>
            <template slot="invoiceStatus" slot-scope="text">
              <a-badge :status="text | color" :text="text | type"></a-badge>
            </template>
            <template slot="schedule" slot-scope="text, record">
              <p>合同：{{ record.contractPro }}</p>
              <p>金额：{{ record.paymentPro }}</p>
              <p>物流：{{record.logisticsPro }}</p>
            </template>
            <template slot="operation" slot-scope="text, record">
              <!-- <p>银行回单</p> -->
              <p v-if="record.invoiceStatus === 'WAIT_APPLY'">
                <a-popconfirm
                  cancelText="取消"
                  okText="确定"
                  title="确定申请?"
                  @confirm="() => putInvo(record.id)">
                <a href="javascript:;" >申请开票</a>
            </a-popconfirm>
              </p>
              <!-- <p @click="pay(record)" v-if="record.contractPro =='100%'&&record.paymentPro!='100%'">
                <a>支付</a>
              </p> -->
            </template>
          </a-table>
          <!-- 支付 -->
          <a-modal
            title="支付"
            :visible="visible"
            :footer="null"
            :width="990"
            @cancel="cancel">
            <PayMent v-if="visible" :id="$route.query.entityId" :authRoleId="authRoleId" :orderId="orders.orderId"/>
          </a-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import cookie from '@/utils/js-cookie.js'
import { orderList,apply  } from '@/api/order'
import { type } from '../../utils/constArr'
import PayMent from './components/payment.vue'
const invoiceState = {
  WAIT_APPLY: '未申请',
  WAIT_AUDIT: '待审核',
  REFUSED: '已拒绝',
  REVIEWED: '审核通过',
  PROCESSING: '开票中',
  WAIT_INVOICE: '待开票',
  INVOICED: '已开票'
}
const color = {
  WAIT_APPLY: 'default',
  WAIT_AUDIT: 'warning',
  REFUSED: 'error',
  REVIEWED: 'success',
  PROCESSING: 'processing',
  WAIT_INVOICE: 'warning',
  INVOICED: 'success'
}
export default {
  components: {
    PayMent
  },
  data() {
    this.dateFormat = 'YYYY-MM-DD'
    return {
      checkEntity: cookie.get('checkEntity') || {},
      columns:[
    {title: '订单号',
    scopedSlots: { customRender: 'id' },
    dataIndex: 'id'
  },
  {
    title: '卖家',
    with: '15%',
    dataIndex: 'supplierName',
    scopedSlots: { customRender: 'supplierName' }
  },
  {
    title: '订单总价(不含增值税)',
    dataIndex: 'taxableAmount',
    align: 'right',
    scopedSlots: { customRender: 'taxableAmount' }
  },
  {
    title: '增值税',
    width: '10%',
    align: 'right',
    dataIndex: 'vatTaxAmount',
    scopedSlots: { customRender: 'vatTaxAmount' }
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    title: '完成度',
    scopedSlots: { customRender: 'schedule' }
  },
  {
    title: '开票状态',
    dataIndex: 'invoiceStatus',
    scopedSlots: { customRender: 'invoiceStatus' }
  },
  {
    title: '下单日期',
    dataIndex: 'createdTime'
  },
  // {
  //   title: '操作',
  //   dataIndex: 'operation',
  //   scopedSlots: { customRender: 'operation' }
  // }
      ],
      selectedRowKeys: [],
      orderList: {},
      getsellerlist: {
        pageNum: 1,
        pageSize: 10,
        totalPage: 1,
        total: 1
      },
      searchText: '',
      beginTime: '',
      endTime: '',
      visible: false,
      authRoleId: undefined, //权限
      orders: {
        orderId: '',
      },
    }
  },
  filters: {
    unit: (val) => type[val],
    type: (val) => invoiceState[val],
    color: (val) => color[val]
  },
  computed: {
    ...mapGetters(['getRoleP']),
  },
  created() {
    this.authRoleId = this.getRoleP.find((item) => item.entityId == this.$route.query.entityId).authRoleId
    this.authRoleId == 2 && this.columns.pop()
    this.getlist()
  },
  methods: {
    // 获取列表
    getlist() {
      const config = {
        purchaserId: this.$route.query.entityId,
        searchText: this.searchText,
        beginTime: this.beginTime,
        endTime: this.endTime,
        pageNum: this.getsellerlist.pageNum,
        pageSize: this.getsellerlist.pageSize
      }
      orderList(config)
        .then((res) => {
          this.getsellerlist.pageNum = res.data.pageNum
          this.getsellerlist.pageSize = res.data.pageSize
          this.getsellerlist.totalPage = res.data.totalPage
          this.getsellerlist.total = res.data.total
          this.orderList = res.data
        })
        .catch((r) => {
          console.log(r)
        })
    },
    onSearch(e) {
      this.searchText = e
      this.getlist()
    },
    onChange(date, dateString) {
      this.beginTime = dateString[0]
      this.endTime = dateString[1]
      this.getlist()
    },
    // 申请开票
    putInvo(id){
      apply({orderId: id}).then(res=>{
        if (res.code===200) {
          this.$message.success(res.message)
          this.getlist()
        }else{
          this.$message.error(res.message)
        }
      }) 
    },
    cancel() {
      this.visible = false
    },
    // 点击支付
    pay(record) {
      this.orders.orderId = record.id
      this.visible = true
    },

  }
}
</script>

<style lang="less" scoped>
.order-management {
  // width: 1190px;
  width: 100%;
  .mainbody {
    .info {
      padding: 10px;
      background: #fff;
      font-size: 14px;
      .info-header {
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        b {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.8);
        }
      }
      .ant-row-flex {
        padding: 20px 0 0 0;
        :nth-child(4) {
          color: #ffffff;
          background-color: rgb(24, 144, 255);
        }
        .ant-col {
          line-height: 30px;
          text-align: center;
          height: 30px;
          font-size: 14px;
          border-radius: 3px;
        }
      }
      .ant-row {
        padding: 20px 0 0 20px;
      }
      .contcat {
        padding: 40px 0 0 0;
      }
      .btn {
        border: none;
        border-radius: 3px;
        width: 130px;
        height: 38px;
      }
      .cl69 {
        background-color: rgba(132, 0, 255, 0.698039215686274);
      }
      .c130 {
        background-color: rgb(130, 130, 130);
      }
      .lh32 {
        line-height: 30px;
        text-align: left;
      }
    }
  }
}
// 支付样式
.left-c {
  line-height: 32px;
  text-align: right;
}
.left-b {
  line-height: 10px;
  text-align: left;
}
.logo {
  width: 143px;
  height: 55px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  img {
    margin-top: 5px;
    width: 100%;
  }
  .pt5 {
    padding: 5px 0 0 0;
  }
}
.zs {
  height: 250px;
}
.pt20 {
  padding: 0 0 0 20px;
}
/deep/ .ant-col {
  margin-bottom: 20px;
}
/deep/ .ant-radio-wrapper {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}
/deep/ .ant-radio {
  &:nth-child(2) {
    width: 100%;
  }
}
/deep/.ant-input-number:hover{
  border-color: red;
}
/deep/.ant-input-number-focused{
  border-color: red;
  box-shadow: none;
}
.notice{
  color: #333;
  font-size: 14px;
}
.red{
  color: red;
}
.col3 {
  font-size: 16px;
  color: #333;
}
</style>