import request from '@/utils/request'


// 订单确认页接口
export function confirm(data) {
    return request({
        url: '/orders/confirm',
        method: 'post',
        data
    })
}
// 新增供应商收货地址
export function createAddress(data) {
    return request({
        url: '/trade/purchaser/addr/create',
        method: 'post',
        data
    })
}
//采购商收货地址列表
export function list(params) {
    return request({
        url: '/trade/purchaser/addr/list',
        method: 'get',
        params
    })
}
// 更新采购商收货地址
export function updateAddress(data) {
    return request({
        url: '/trade/purchaser/addr/update',
        method: 'post',
        data
    })
}
//获取采购商默认地址
export function getdefault(params) {
    return request({
        url: '/trade/purchaser/addr/default',
        method: 'get',
        params
    })
}
// 设置为默认收货地址
export function setdefault(data) {
    return request({
        url: '/trade/purchaser/addr/set/default',
        method: 'post',
        data
    })
}
// 删除采购商地址
export function deleteAddress(data) {
    return request({
        url: '/trade/purchaser/addr/delete',
        method: 'post',
        data
    })
}
// 订单生成
export function createOrder(data) {
    return request({
        url: '/orders/create',
        method: 'post',
        data
    })
}
// 订单列表页数据获取
export function orderList({ searchText, invoiceStatusArray, logisticsStatusArray, beginTime, endTime, supplierId, purchaserId, pageNum, pageSize }) {
    return request({
        url: '/orders/list',
        method: 'get',
        params: { searchText, invoiceStatusArray, logisticsStatusArray, beginTime, endTime, supplierId, purchaserId, pageNum, pageSize }
    })
}

/**
 * @param {*} orderId 参数
 * @returns 个人申请列表
 */
export function infoflow(orderId) {
    return request({
        url: `/orders/${orderId}/infoflow`,
        method: 'get'
    })
}

//票流详情
export function detail(params) {
    return request({
        url: '/trade/invoice/stream/detail',
        method: 'get',
        params
    })
}
// 申请开票
export function apply(data) {
    return request({
        url: '/trade/invoice/apply',
        method: 'post',
        data
    })
}
// 合同列表
export function findcontract(orderId) {
    return request({
        url: '/contract/findcontract',
        method: 'get',
        params: { orderId }
    })
}
// 合同重发短信
export function send(id, entityCharacter) {
    return request({
        url: '/contract/send',
        method: 'get',
        params: { id, entityCharacter }
    })
}

// 合同删除重发
export function anew(id) {
        return request({
            url: '/contract/anew',
            method: 'get',
            params: { id }
        })
    }
// 合同下载
export function download(id) {
    return request({
        url: '/contract/download',
        method: 'get',
        params: { id }
    })
}
// 电子凭证下载
export function voucher(id,entityCharacter) {
    return request({
        url: '/contract/download/voucher',
        method: 'get',
        params: { id,entityCharacter }
    })
}
// 添加物流信息
export function addLogistics(data) {
    return request({
        url: '/tax/logistics/add',
        method: 'post',
        data
    })
}
// 添加物流图片
export function addImg(data) {
    return request({
        url: '/tax/logistics/add/img',
        method: 'post',
        data
    })
}
// 返回订单收发货地址
export function getAddress(orderId) {
    return request({
        url: '/tax/logistics/address',
        method: 'get',
        params: {orderId}
    })
}
// 物流信息列表
export function getLogistics(orderId) {
    return request({
        url: '/tax/logistics/list',
        method: 'get',
        params: {orderId}
    })
}
// 物流信息删除
export function delLogistics(data) {
    return request({
        url: '/tax/logistics/del',
        method: 'post',
        data
    })
}
// 物流信息图片删除
export function delImg(data) {
    return request({
        url: '/tax/logistics/del/img',
        method: 'post',
        data
    })
}
// 物流信息图片获取
export function getImg(orderLogisticsId) {
    return request({
        url: '/tax/logistics/img/list',
        method: 'get',
        params: {orderLogisticsId}
    })
}
// 物流信息进度
export function schedule(params) {
    return request({
        url: '/tax/logistics/schedule',
        method: 'get',
        params
    })
}
// 编辑物流信息
export function edit(data) {
    return request({
        url: '/tax/logistics/edit',
        method: 'post',
        data
    })
}
// 编辑物流图片
export function editImg(data) {
    return request({
        url: '/tax/logistics/edit/img',
        method: 'post',
        data
    })
}


