<template>
    <div class="payment">
         <a-row>
              <a-col :span="3" class="col3" align='center'><span>未付总额：</span> </a-col>
              <a-col :span="21">
                <a-space :size="30">         
                  <span>￥{{ payAll.fee }}</span>
                
                  <span><a-checkbox value='1' @change="netFee" :checked="stateNetFee"></a-checkbox> 卖家收款：￥{{
                      payAll.netFee
                    }}</span>
                  <span
                    ><a-checkbox  value='2' @change="taxFee" :checked="stateTaxFee"></a-checkbox>
                    平台代扣税款：￥{{ payAll.taxFee }}</span>
                </a-space>
              </a-col>
            </a-row>
            <a-row style="line-height:32px">
              <a-col :span="3" class="col3" align='center'>支付金额：</a-col>
              <a-col :span="6" class="col3"
                ><a-input-number
                 :formatter="value => `￥ ${value}`"
                  ref="fous"
                  @change="stateNetFee=false;stateTaxFee=false;"
                  style="width: 200px"
                  :precision="2"
                   v-model="orders.payAmount"
                  :min="0"
              />
                <p class="notice">注：系统默认优先付清税款</p>
              </a-col>
               <!-- eslint-disable-next-line vue/no-parsing-error -->
              <a-col :span="15" class="red" v-if="orders.payAmount && payAll.taxFee&&orders.payAmount<=payAll.fee">*平台代扣税款：{{orders.payAmount>=payAll.taxFee?(payAll.taxFee):orders.payAmount}} ，卖家收款：{{orders.payAmount<=payAll.taxFee?0:(orders.payAmount-payAll.taxFee).toFixed(2)}}</a-col>
              <a-col :span="15" v-if="orders.payAmount>payAll.fee"><span class="red">*超出未付总额{{payAll.fee}}</span></a-col>
              <a-col :span="15" v-if="orders.payAmount===0"><span class="red">请输入支付金额</span></a-col>
            </a-row>
     
            <a-row v-if="this.authRoleId == 3||this.authRoleId==1">
              <a-col :span="3" class="col3" align='center'
                >支付渠道：</a-col
              >
              <a-col :span="21">
                <a-radio-group v-model="radio" @change="onRadio">
                  <a-radio
                    class="l-flex-aligncenter"
                    :style="radio == 1 ? radioStyle : ''"
                    :value="1"
                  >
                    <div class="l-flex-between">
                      <div class="logo">
                        <img :src="Logo" alt="" />
                      </div>
                      <div class="l-flex-between" style="width: 570px">
                        <p class="pt20">网关支付</p>
                        <p>
                          <span>实时到账</span>&nbsp;&nbsp;
                          <span>手续费 {{stat==true || !orders.payAmount?0:(orders.payAmount*0.0011 > 0.01 ? Math.ceil(orders.payAmount*0.0011*100)/100 : 0.01)}} 元</span>
                        </p>
                      </div>
                    </div>
                  </a-radio>
                  <!-- <a-radio
                class="l-flex-aligncenter"
                :style="radio == 2 ? radioStyle : ''"
                :value="2">
                <div class="l-flex-between">
                  <div class="logo">
                    <img src="@/assets/images/u5315.png" alt="" />
                  </div>
                  <div class="l-flex-between" style="width: 570px">
                    <p class="pt20">
                      <span>余额支付</span> |
                      <span>账号：</span>
                      <span>6500000****1234</span> |
                      <span>余额：</span>
                      <span>￥1,200,000.00</span>
                    </p>
                    <p>
                      <span>实时到账</span>&nbsp;&nbsp;
                      <span>手续费3元</span>
                    </p>
                  </div>
                </div>
              </a-radio> -->
                </a-radio-group>
              </a-col>
            </a-row>
            <!-- form格式提交支付 -->
            <form :action="form.reqUrl" method="post" v-show="false" target="_blank">
              <input type="text" :value="form.method" name="method" />
              <input type="text" :value="form.version" name="version" />
              <input type="text" :value="form.merId" name="merId" />
              <input type="text" :value="form.date" name="date" />
              <input type="text" :value="form.token" name="token" />
              <input type="text" :value="form.signMethod" name="signMethod" />
              <input type="text" :value="form.sign" name="sign" />
              <input type="submit" ref="sub" />
            </form>
            <!-- 选择支付方式 -->
            <a-row class="zs" v-if="radio == 1 && (this.authRoleId == 3||this.authRoleId == 1)">
              <a-col :span="6" class="left-c">选择支付账号</a-col>
              <a-col :span="18" class="pt20">
                <a-select v-model="bankSelect" @change="handleChange" style="width: 510px"><a-select-option
                    v-for="item in payAll.bankAccountList"
                    :key="item.bankAccount"
                    >{{ item.purchaserName+' '+item.bankAccount+' '+item.bankName  }}</a-select-option
                  ></a-select
                ></a-col
              >
              <a-col :span="6" class="left-c">支付账户类型</a-col>
              <a-col :span="18" class="pt20">
                <a-input value="企业" style="width: 510px" disabled></a-input>
                  </a-col>
              <a-col :span="6" class="left-c">银行账号</a-col>
              <a-col :span="18" class="pt20">
                <a-input ref="fou" class="bankNo" :maxLength="30" @change="bankText=false" v-model="orders.bankNo" style="width: 510px"></a-input>
                <p class="red" v-if="bankText">请输入正确的银行账号</p>
              </a-col>
            </a-row>

            <a-row class="zs" v-if="radio == 2">
              <a-col :span="4"></a-col>
              <a-col :span="20" class="left-b">中再物贸云余额支付密码：</a-col>
              <a-col :span="4"></a-col>
              <a-col :span="20" class="left-b">
                <PayMoney @pwd="msg = $event" />
              </a-col>
              <a-col :span="4"></a-col>
              <a-col :span="20" class="left-b">请输入6位数余额支付密码 </a-col>
            </a-row>
            <a-row class="next">
              <a-col :sapn="24" align="center" v-if="this.authRoleId == 3 ||this.authRoleId==1">
                <a-button type="primary" @click="sure" :loading="loading">
                  确认支付
                </a-button>
              </a-col>
              <a-col v-else align="center">
                <a-button @click="sub" type="primary"> 提交支付 </a-button>
              </a-col>
            </a-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { pay, first, info } from '@/api/pay'
import PayMoney from '@/components/payInput.vue'
import Logo from '@/assets/images/u154.png'
export default {
    props:['id','authRoleId','orderId','paymentId',],
    components:{
       PayMoney
    },
    data(){
        return{
      Logo:Logo,
      radio: 1,
      radioStyle: {
        background: 'rgba(255, 204, 102, 1)'
      },
      msg: '',
      // 银行支付
      form: {
        method: '',
        version: '',
        merId: '',
        date: '',
        token: '',
        sign: '',
        reqUrl: '',
        signMethod: ''
      },

      loading: false,
      orders: {
        //直接支付
        purchaserId: this.id, //订单id
        orderId: this.orderId,
        payAmount: null,
        bankName: '',
        bankNo: ''
      },
      bankSelect:'',
      payAll: {
        bankAccountList: [],
        fee: undefined, //总费用
        netFee: undefined, //贷款
        taxFee: undefined //税款
      },
        stateNetFee: false, //贷款
        stateTaxFee: false,//税款
        bankText:false,
        stat:false,
        }
    },
      computed: {
    ...mapGetters(['getRoleP']),
  },
  created(){
   this.getInfo()
  },
  watch:{
    //    payMount(){
    //       this.orders.payAmount.replace(/\d+.?\d{0,2}/,'')
    //    }
  },
  methods:{
       getInfo() {
      const config = {
        orderId: this.orderId,
        paymentId: this.paymentId,
        purchaserId: this.id
      }
      info(config)
        .then((res) => {
          if (res.code === 200) {
            this.payAll = res.data
            this.bankSelect=res.data.bankAccountList[0].purchaserName+' '+res.data.bankAccountList[0].bankAccount+' '+res.data.bankAccountList[0].bankName
            this.orders.bankNo=res.data.bankAccountList[0].bankAccount
            this.orders.bankName=res.data.bankAccountList[0].bankName
            this.getVaild()
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((r) => {
          console.log(r)
        })
    },
     onRadio(e) {
      this.radio = e.target.value
    },
    // 确认支付
    sure() {
      if (this.radio === 1 && (this.authRoleId == 3||this.authRoleId == 1)) {
          this.getVaild()
       if (!this.verify()) return 
       if(this.bankText==true) return
        this.loading = true
        pay({...this.orders}).then((res) => { 
            this.loading = false
          if (res.code === 200) {
            this.form = res.data
            this.$nextTick(() => {
            this.$refs.sub.click()
            })
          } else {
            this.$message.error(res.message)
          }
        }).catch(()=>{
          this.loading = false
        })
      } else if (this.radio === 2) {
        if (this.msg.length != 6) {
          this.$message.error('请输入6位支付密码')
        } else return
      }
    },
    sub() {
      if (Number(this.orders.payAmount)==0||this.orders.payAmount>this.payAll.fee) {
        this.$message.error('请输入支付金额')
        return
      }
      first({ ...this.orders }).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.$emit.getlist()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //选择银行卡
    handleChange(e) {
    const bankNa= this.payAll.bankAccountList.find((item) => {
        return item.bankAccount === e
      })
      this.bankSelect=bankNa.purchaserName+' '+bankNa.bankAccount+' '+bankNa.bankName
      this.orders.bankName=bankNa.bankName
      this.orders.bankNo=e
     this.getVaild()
    },
    getVaild(){
        this.bankText=false
        this.stat=true
        //  valid({cardNo:this.orders.bankNo}).then(res=>{
        //   if (res.code===200) {
        //       this.bankText=false
        //       this.stat=res.data.stat
        //   }else{
        //       this.stat=false
        //       this.bankText=true 
        //   }
      // })
    },
    //货款
    netFee(e) {
      if(e.target.checked==true&&this.stateTaxFee==true){
        this.stateNetFee=true
        this.orders.payAmount=this.payAll.netFee+this.payAll.taxFee
        return
      }
      if (e.target.checked==true) {
        this.stateNetFee=true
        this.orders.payAmount=this.payAll.netFee
      } 
      else{
        this.stateNetFee=false
        this.orders.payAmount=(this.orders.payAmount-this.payAll.netFee).toFixed(2)
      }
    },
    //税款
    taxFee(e) {
        if(e.target.checked==true&&this.stateNetFee==true){
        this.stateTaxFee=true
        this.orders.payAmount=this.payAll.netFee+this.payAll.taxFee
        return
       }
        if ( e.target.checked == true) {
        this.stateTaxFee=true
        this.orders.payAmount=this.payAll.taxFee
        }else{
        this.stateTaxFee=false
        this.orders.payAmount=(this.orders.payAmount-this.payAll.taxFee).toFixed(2)

        }
    },
    verify() {
      if (Number(this.orders.payAmount)==0||this.orders.payAmount>this.payAll.fee) {
        // this.$message.error('请输入支付金额')
        this.orders.payAmount=0
        console.log(this.$refs.fous)
        this.$refs.fous.focus()
      }else if (this.payAll.bankAccountList.length==0) {
        this.$message.error('请先添加银行卡再进行支付')
      }else if (!this.orders.bankName) {
        this.$message.error('请选择支付账号')
      } else if (!this.orders.bankNo) {
        // this.$message.error('请输入银行账号')
        this.bankText=true
        this.$refs.fou.focus()
      } else return true
    }
  }


}
</script>

<style lang="less" scoped>
.left-c {
  line-height: 32px;
  text-align: right;
}
.left-b {
  line-height: 10px;
  text-align: left;
}
.logo {
  width: 143px;
  height: 55px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  img {
    margin-top: 5px;
    width: 100%;
  }
  .pt5 {
    padding: 5px 0 0 0;
  }
}
.zs {
  height: 250px;
}
.pt20 {
  padding: 0 0 0 20px;
}
/deep/ .ant-col {
  margin-bottom: 20px;
}
/deep/ .ant-radio-wrapper {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}
/deep/ .ant-radio {
  &:nth-child(2) {
    width: 100%;
  }
}
/deep/.ant-input-number:hover{
  border-color: red;
}
/deep/.ant-input-number-focused{
  border-color: red;
  box-shadow: none;
}
/deep/.bankNo{
  box-shadow: none;
  border-color: none;
  &:hover{
      border-color: red ;
  }
  &:focus{
      border-color:red ;
  }
}
.notice{
  color: #333;
  font-size: 14px;
}
.red{
  color: red;
}
.col3 {
  font-size: 16px;
  color: #333;
}
</style>